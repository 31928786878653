<template>
    <div class="px-3 py-2">
        <v-form
            ref="formcc"
            lazy-validation 
        >
        <b-sidebar backdrop id="add_unit" width="700px" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right title="اضافة مهمة" shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{ slide_title }}</span>
                </div>
                <div @click="hide" id="hideAddUnit" style="text-align:center;width:100px;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
            </div>
            <v-divider></v-divider>
            <v-row class="m-2" style="direction:rtl">
                <v-col cols="12" md="6">
                    <label>{{ lang.namear }}</label>
                    <b-form-input class="inborder" v-model="unit.namear" />
                </v-col>
                <v-col cols="12" md="6">
                    <label>{{ lang.nameen }}</label>
                    <b-form-input class="inborder" v-model="unit.nameen" />
                </v-col>
                <v-col cols="12" md="6">
                    <label>{{ lang.unit_type }}</label>
                    <b-form-select class="selborder" v-model="unit.type" :options="unitsTypes" />
                </v-col>
                <v-col cols="12" md="6">
                    <label>{{ lang.main_unit }}</label>
                    <b-form-select class="selborder" v-model="unit.main_unit" :options="mainUnits" />
                </v-col>
                <v-col cols="12" md="6">
                    <label>{{ lang.qtty }}</label>
                    <b-form-input class="inborder" v-model="unit.qty" />
                </v-col>
            </v-row>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addUnit()" variant="success" class="ma-2" style="width:100px;">{{ lang.save }}</b-button>
                </div>
            </template>
        </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            slide_title: '',
            unit: {
                id: 0,
                namear: '',
                nameen: '',
                main_unit: 0,
                type: 1,
                qty: 1,
            },
            units: [],
            unit_types: []
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        unitsTypes: function(){
            let t = [];
            for(let i=0;i<this.unit_types.length;i++){
                t.push({
                    text: this.unit_types[i][`name_${this.lang.langname}`], 
                    value: this.unit_types[i].value
                })
            }
            return t;
        },
        mainUnits: function(){
            let t = [
                {text: this.lang.main_unit, value: 0}
            ];
            for(let i=0;i<this.units.length;i++){
                if(this.units[i].type == 1){
                    t.push({
                        text: this.units[i][`name${this.lang.langname}`], 
                        value: this.units[i].id
                    })
                }
            }
            return t;
        },
    },
    methods: {
        addUnit() {
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','addUnits');
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            Object.keys(this.unit).forEach(key => {
                post.append('data[units]['+key+']',this.unit[key]);
            })
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.getUnits();
                    this.$parent.getUnits();
                }
            })
        },
        resetValues(data){
            this.slide_title = data.title;
            this.unit = data.unit;
        },
        getUnits(){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const post = new FormData();
            post.append('type','getUnits');
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[units]',0)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.units = response.data.results.data;
                    this.unit_types = response.data.results.unit_types;
                }
            })
        }
    },
    created() {
        this.getUnits();
    },
}
</script>